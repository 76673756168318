/* AdminLayout.css */
.admin-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.admin-header {
    background-color: #2a3d66;
    color: white;
    padding: 10px;
    text-align: center;
}

.admin-sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding-top: 20px;
    position: fixed;
    height: 100%;
}

    .admin-sidebar ul {
        list-style-type: none;
        padding: 0;
    }

    .admin-sidebar li {
        padding: 10px;
    }

    .admin-sidebar a {
        text-decoration: none;
        color: #333;
    }

        .admin-sidebar a:hover {
            background-color: #ddd;
            padding-left: 10px;
        }

.admin-main {
    margin-left: 220px;
    padding: 20px;
    flex-grow: 1;
}

.admin-footer {
    background-color: #2a3d66;
    color: white;
    text-align: center;
    padding: 10px;
}
