.course-schedule {
	padding: 20px;
	font-family: Arial, sans-serif;
}

.search-schedule,
.add-schedule {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 20px;
}

	.search-schedule input,
	.add-schedule input {
		padding: 8px;
		border-radius: 5px;
		border: 1px solid #ccc;
		font-size: 14px;
		height: 40px; /* Consistent height for alignment */
	}

	.search-schedule button,
	.add-schedule button {
		padding: 8px 15px;
		border-radius: 5px;
		background-color: #28a745; /* Green for search */
		color: white;
		border: none;
		cursor: pointer;
		font-size: 14px;
		height: 40px; /* Consistent height for alignment */
	}

		.search-schedule button:hover,
		.add-schedule button:hover {
			background-color: #218838;
		}

	.add-schedule button {
		background-color: #007bff; /* Blue for add */
	}

		.add-schedule button:hover {
			background-color: #0056b3;
		}

/* Table styling */
table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
	background-color: #fff;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

table, th, td {
	border: 1px solid #ddd;
}

th, td {
	padding: 12px;
	text-align: left;
}

th {
	background-color: #f4f4f4;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 1px;
}

td {
	color: #333;
}

table tbody tr:nth-child(odd) {
	background-color: #f9f9f9;
}

table tbody tr:nth-child(even) {
	background-color: #ffffff;
}

table tbody tr:hover {
	background-color: #eef4fa;
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.search-schedule, .add-schedule {
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
	}

		.search-schedule input, .add-schedule input,
		.search-schedule button, .add-schedule button {
			width: 100%;
		}
}
