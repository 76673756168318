/* Login.css */

.login__form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.login__label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

.login__input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

.login__button {
    width: 100%;
    padding: 10px;
    background-color: '#34a8eb';
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

    .login__button:hover {
        background-color: #45a049;
    }
