/* Overall page background */
body {
    background-color: #f0f2f5; /* Soft gray background for better contrast */
    background-image: linear-gradient(to right, #f0f2f5, #d9e2ec);
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Overall styling for instructors table */
.instructors-table {
    margin: 20px auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px; /* Keeps content centered on the page */
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for card-like effect */
}

    .instructors-table h2 {
        color: #444;
        text-align: center;
        font-size: 28px;
        margin-bottom: 20px;
        font-weight: 600;
    }

    /* Table styling */
    .instructors-table table {
        width: 100%;
        border-collapse: collapse;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }

    .instructors-table th, .instructors-table td {
        padding: 14px 18px; /* Consistent padding */
        text-align: left;
        font-size: 16px;
    }

    .instructors-table th {
        background-color: #0077cc; /* Professional primary blue */
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .instructors-table td {
        border-bottom: 1px solid #ddd;
    }

    .instructors-table tbody tr:nth-child(odd) {
        background-color: #f9fbfd;
    }

    .instructors-table tbody tr:nth-child(even) {
        background-color: #ffffff;
    }

    .instructors-table tbody tr:hover {
        background-color: #eef4fa;
        cursor: pointer;
    }

/* Dropdown filter styling */
.filter-dropdown {
    margin-bottom: 15px; /* Reduced space between dropdown and table */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

    .filter-dropdown label {
        font-weight: bold;
        margin-right: 8px;
        font-size: 16px;
    }

    .filter-dropdown select {
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        background-color: #fff;
        color: #333;
        transition: all 0.3s ease;
        min-width: 180px; /* Adjusted dropdown width for tighter fit */
    }

        .filter-dropdown select:hover {
            background-color: #f4f4f4;
            border-color: #0077cc;
        }

/* Button styles */
.save-button, .cancel-button, .update-button, .delete-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.save-button {
    background-color: #28a745;
    color: white;
}

    .save-button:hover {
        background-color: #218838;
    }

.cancel-button {
    background-color: #6c757d;
    color: white;
}

    .cancel-button:hover {
        background-color: #5a6268;
    }

.update-button {
    background-color: #007bff;
    color: white;
}

    .update-button:hover {
        background-color: #0056b3;
    }

.delete-button {
    background-color: #dc3545;
    color: white;
}

    .delete-button:hover {
        background-color: #c82333;
    }

/* Adjusting button placement for closer grouping */
.instructors-table .button-group {
    display: flex;
    justify-content: flex-start;
    gap: 8px; /* Reduces space between buttons */
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
    .instructors-table table, .instructors-table th, .instructors-table td {
        font-size: 14px;
        padding: 12px;
    }

    .filter-dropdown {
        flex-direction: column;
        align-items: flex-start;
    }
}

/* Adjusted filter-dropdown and input box styling for alignment */
.filter-dropdown {
    margin-bottom: 15px; /* Space between dropdown and table */
    display: flex;
    justify-content: flex-start; /* Align everything to the left */
    align-items: center; /* Vertically align everything */
    gap: 10px; /* Ensure spacing between input, buttons */
}

    .filter-dropdown input {
        padding: 10px 15px; /* Match the height of the buttons */
        font-size: 16px; /* Make font size consistent with buttons */
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 250px; /* Adjust width as needed */
    }

    .filter-dropdown button {
        padding: 10px 15px; /* Consistent padding with input field */
        font-size: 16px; /* Match font size with input */
        border-radius: 5px;
        cursor: pointer;
    }

    /* Adjust Find and Create New button hover styles */
    .filter-dropdown .btn:hover {
        background-color: #0077cc; /* Professional blue on hover */
        color: white;
    }
/* Container to align the search input and buttons */
.search-and-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

    /* Style the search input */
    .search-and-buttons input[type="text"] {
        padding: 10px;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid #ccc;
        flex: 1;
        margin-right: 10px; /* Creates space between input and buttons */
    }

    /* Style the buttons */
    .search-and-buttons button {
        padding: 10px 15px;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 10px; /* Creates space between buttons */
    }

    .search-and-buttons .btn-find {
        background-color: #007bff;
        color: white;
    }

        .search-and-buttons .btn-find:hover {
            background-color: #0056b3;
        }

    .search-and-buttons .btn-create {
        background-color: #28a745;
        color: white;
    }

        .search-and-buttons .btn-create:hover {
            background-color: #218838;
        }
