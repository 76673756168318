/* General styles for the page */
body {
    background-color: #f0f2f5;
    background-image: linear-gradient(to right, #f0f2f5, #d9e2ec);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* If needed, you can add additional styling for the main container */
.main-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}
