/* General styles for the page */
body {
    background-color: #f0f2f5;
    background-image: linear-gradient(to right, #f0f2f5, #d9e2ec);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Align the search bar and buttons */
.search-and-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center; /* Align vertically */
    gap: 10px; /* Space between input and buttons */
    margin-bottom: 20px;
}

    /* Adjust input width to be consistent with buttons */
    .search-and-buttons input[type="text"] {
        width: 300px; /* You can adjust this width to better fit your layout */
        padding: 10px;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid #ccc;
        height: 42px; /* Ensure it matches the button height */
    }

    /* Button styles */
    .search-and-buttons .btn {
        padding: 10px 15px;
        font-size: 16px;
        border-radius: 5px;
        background-color: #007bff;
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        height: 42px; /* Make buttons height match the input box */
    }

        .search-and-buttons .btn:hover {
            background-color: #0056b3;
        }

.students-table {
    margin: 20px auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px;
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

    .students-table h2 {
        color: #444;
        text-align: center;
        font-size: 28px;
        margin-bottom: 20px;
        font-weight: 600;
    }

    /* Table styling */
    .students-table table {
        width: 100%;
        border-collapse: collapse;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }

    .students-table th,
    .students-table td {
        padding: 14px 18px;
        text-align: left;
        font-size: 16px;
    }

    .students-table th {
        background-color: #0077cc;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .students-table td {
        border-bottom: 1px solid #ddd;
    }

    .students-table tbody tr:nth-child(odd) {
        background-color: #f9fbfd;
    }

    .students-table tbody tr:nth-child(even) {
        background-color: #ffffff;
    }

    .students-table tbody tr:hover {
        background-color: #eef4fa;
        cursor: pointer;
    }

/* Dropdown filter styling */
.filter-dropdown {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

    .filter-dropdown label {
        font-weight: bold;
        margin-right: 8px;
        font-size: 16px;
    }

    .filter-dropdown select {
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        background-color: #fff;
        color: #333;
        transition: all 0.3s ease;
        min-width: 180px;
    }

        .filter-dropdown select:hover {
            background-color: #f4f4f4;
            border-color: #0077cc;
        }

/* Button styles */
.save-button,
.cancel-button,
.update-button,
.delete-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.save-button {
    background-color: #28a745;
    color: white;
}

    .save-button:hover {
        background-color: #218838;
    }

.cancel-button {
    background-color: #6c757d;
    color: white;
}

    .cancel-button:hover {
        background-color: #5a6268;
    }

.update-button {
    background-color: #007bff;
    color: white;
}

    .update-button:hover {
        background-color: #0056b3;
    }

.delete-button {
    background-color: #dc3545;
    color: white;
}

    .delete-button:hover {
        background-color: #c82333;
    }

/* Adjusting button placement for closer grouping */
.students-table .button-group {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
    .students-table table,
    .students-table th,
    .students-table td {
        font-size: 14px;
        padding: 12px;
    }

    .filter-dropdown {
        flex-direction: column;
        align-items: flex-start;
    }

    .search-and-buttons {
        flex-direction: column;
        align-items: flex-start;
    }

        .search-and-buttons input[type="text"] {
            width: 100%; /* Make the search input take the full width */
        }
}
