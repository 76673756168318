body {
    background-color: #f0f2f5;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.events-table {
    margin: 20px auto;
    max-width: 1200px;
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

    .events-table h2 {
        color: #444;
        text-align: center;
        font-size: 28px;
        margin-bottom: 20px;
        font-weight: 600;
    }

    .events-table table {
        width: 100%;
        border-collapse: collapse;
    }

    .events-table th, .events-table td {
        padding: 14px 18px;
        text-align: left;
        font-size: 16px;
    }

    .events-table th {
        background-color: #0056b3;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .events-table td {
        border-bottom: 1px solid #ddd;
    }

    .events-table tbody tr:nth-child(odd) {
        background-color: #f9f9f9;
    }

    .events-table tbody tr:nth-child(even) {
        background-color: #ffffff;
    }

    .events-table tbody tr:hover {
        background-color: #eef4fa;
        cursor: pointer;
    }

.filter-dropdown {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.save-button, .cancel-button, .update-button, .delete-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.save-button {
    background-color: #28a745;
    color: white;
}

.cancel-button {
    background-color: #6c757d;
    color: white;
}

.update-button {
    background-color: #007bff;
    color: white;
}

.delete-button {
    background-color: #dc3545;
    color: white;
}
