/* Close button (X) in the top-right corner */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 2.2em;
    color: white;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease;
}

.close-btn {
     color: red;
     transform: scale(1.1);
}

/* Form container */
.form-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Form group styles */
.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.form-group label {
     font-size: 1.4em;
     font-weight: 500;
     color: white;
}

.form-group input,
.form-group textarea {
     padding: 10px;
     font-size: 1.2em;
     border: 1px solid #ccc;
     border-radius: 20px;
     width: 40%;
     transition: border-color 0.3s ease;
}

 /* Focus effect on input fields */
.form-group input:focus,
.form-group textarea:focus {
     border-color: #007bff;
     outline: none;
}

/* Make textarea scrollable and resizeable */
textarea {
    resize: vertical;
    min-height: 100px;
}

/* Submit button styles */
.submit-btn {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 20px;
    width: 50%;
    font-size: 1.6em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto;
    text-align: center;
}

 .submit-btn:hover {
    background-color: #0056b3;
 }

/* Title styles */
.create-event-popup h2 {
    text-align: center;
    color: white;
    font-size: 2.4em;
    margin-top: 20px;
}
