.event-details-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

h2, h3 {
    color: #333;
}

.button-group {
    margin-top: 10px;
}

.edit-event-btn, .delete-event-btn {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

    .edit-event-btn:hover {
        background-color: #2980b9;
    }

.delete-event-btn {
    background-color: #e74c3c;
    margin-left: 10px;
}

    .delete-event-btn:hover {
        background-color: #c0392b;
    }

.edit-event-form {
    margin-top: 15px;
    background: #f0f0f0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

    .edit-event-form input, .edit-event-form textarea {
        padding: 5px;
        margin: 5px 0;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
    }

    .edit-event-form button {
        margin: 5px;
        padding: 5px 10px;
        cursor: pointer;
    }
