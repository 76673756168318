.instructor-details-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.instructor-info {
    margin-bottom: 20px;
}

h2, h3 {
    color: #333;
}

ul {
    list-style-type: none;
    padding: 0;
}

.course-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #e0e0e0;
    margin: 8px 0;
    border-radius: 5px;
}

.button-group {
    display: flex;
    gap: 10px;
}

.update-course-btn {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

    .update-course-btn:hover {
        background-color: #2980b9;
    }

.delete-course-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

    .delete-course-btn:hover {
        background-color: #c0392b;
    }

.add-course-btn {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 8px 12px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

    .add-course-btn:hover {
        background-color: #27ae60;
    }

.add-course-form, .edit-course-form {
    margin-top: 15px;
    background: #f0f0f0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

    .add-course-form input, .edit-course-form input {
        padding: 5px;
        margin-right: 5px;
        width: 150px; /* Adjusted width to accommodate multiple fields */
        border: 1px solid #ccc;
        border-radius: 3px;
    }

        /* Specific styles for each input in the add-course form */
        .add-course-form input[name="course_id"],
        .add-course-form input[name="instructor_id"],
        .add-course-form input[name="subject"] {
            margin-bottom: 10px; /* Adds space between input fields */
        }

    .add-course-form button, .edit-course-form button {
        margin: 5px;
        padding: 5px 10px;
        cursor: pointer;
    }

/* Styles for the list of students within a course */
.student-list {
    list-style-type: none;
    margin: 10px 0 0 20px; /* Left padding for indentation */
    padding: 0;
}

    .student-list li {
        background-color: #e0e0e0;
        margin: 4px 0;
        padding: 6px;
        border-radius: 4px;
    }
