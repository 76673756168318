.create-course {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: black; /* Set font color to black for all text in the .create-course div */
}

.close-btn:hover {
    color: red;
    transform: scale(1.1);
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: black; /* Set font color to black for all text in the form-container div */
}

.form-group label {
    flex: 1;
    margin-right: 10px;
    font-size: 16px;
    color: black; /* Set font color to black for labels */
}

.form-group input {
    flex: 2;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: black; /* Set font color to black for input text */
}

.submit-btn {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

    .submit-btn:hover {
        background-color: #0056b3;
    }
