/* Container for the entire student details section */
.student-details-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

h3 {
    font-size: 20px;
    font-weight: bold;
    color: #555;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    color: #444;
    margin-bottom: 10px;
}

/* List of parents or courses */
ul {
    list-style-type: none;
    padding: 0;
}

li {
    font-size: 16px;
    padding: 8px 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    border-radius: 4px;
    color: #333;
}

/* No data message */
.no-data {
    font-style: italic;
    color: #999;
}

/* Table for structured data (if needed for future use) */
.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

    .table th, .table td {
        border: 1px solid #ddd;
        padding: 12px;
        text-align: left;
    }

    .table th {
        background-color: #f0f0f0;
        font-weight: bold;
        color: #333;
    }

    .table td {
        background-color: #fff;
        color: #555;
    }

/* Button styles for actions */
button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 10px;
}

    button:hover {
        background-color: #0056b3;
    }

    button.delete {
        background-color: #dc3545;
    }

        button.delete:hover {
            background-color: #c82333;
        }
