.create-instructor {
	max-width: 600px;
	margin: 0 auto;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.form-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

	.form-group label {
		flex: 1;
		margin-right: 10px;
		font-size: 16px;
	}

	.form-group input {
		flex: 2;
		padding: 10px;
		font-size: 16px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}

.submit-btn {
	padding: 10px 15px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 4px;
	font-size: 16px;
	cursor: pointer;
}

	.submit-btn:hover {
		background-color: #0056b3;
	}

