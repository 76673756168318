body {
    background-color: #f0f2f5;
    background-image: linear-gradient(to right, #f0f2f5, #d9e2ec);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.courses-table {
    margin: 20px auto;
    max-width: 1200px;
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

    .courses-table h2 {
        color: #444;
        text-align: center;
        font-size: 28px;
        margin-bottom: 20px;
        font-weight: 600;
    }

    .courses-table table {
        width: 100%;
        border-collapse: collapse;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }

    .courses-table th, .courses-table td {
        padding: 14px 18px;
        text-align: left;
        font-size: 16px;
    }

    .courses-table th {
        background-color: #0077cc;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .courses-table td {
        border-bottom: 1px solid #ddd;
    }

    .courses-table tbody tr:nth-child(odd) {
        background-color: #f9fbfd;
    }

    .courses-table tbody tr:nth-child(even) {
        background-color: #ffffff;
    }

    .courses-table tbody tr:hover {
        background-color: #eef4fa;
        cursor: pointer;
    }

.filter-dropdown {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

    .filter-dropdown label {
        font-weight: bold;
        margin-right: 8px;
        font-size: 16px;
    }

    .filter-dropdown select {
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        min-width: 180px;
    }

.save-button, .cancel-button, .update-button, .delete-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.save-button {
    background-color: #28a745;
    color: white;
}

.cancel-button {
    background-color: #6c757d;
    color: white;
}

.update-button {
    background-color: #007bff;
    color: white;
}

.delete-button {
    background-color: #dc3545;
    color: white;
}

.schedule-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px; /* Add space if needed */
}

    .schedule-btn:hover {
        background-color: #0056b3;
    }

.course-schedule-button {
    margin-top: 10px; /* Adjust as needed for spacing */
    display: flex;
    justify-content: flex-start;
}
