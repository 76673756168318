/* Overall page background */
body {
    background-color: #f0f2f5; /* Soft gray background for better contrast */
    background-image: linear-gradient(to right, #f0f2f5, #d9e2ec);
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Overall styling for parents table */
.parents-table {
    margin: 20px auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px; /* Keeps content centered on the page */
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for card-like effect */
}

    .parents-table h2 {
        color: #444;
        text-align: center;
        font-size: 28px;
        margin-bottom: 20px;
        font-weight: 600;
    }

    /* Table styling */
    .parents-table table {
        width: 100%;
        border-collapse: collapse;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }

    .parents-table th, .parents-table td {
        padding: 14px 18px; /* Consistent padding */
        text-align: left;
        font-size: 16px;
    }

    .parents-table th {
        background-color: #0077cc; /* Professional primary blue */
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .parents-table td {
        border-bottom: 1px solid #ddd;
    }

    .parents-table tbody tr:nth-child(odd) {
        background-color: #f9fbfd;
    }

    .parents-table tbody tr:nth-child(even) {
        background-color: #ffffff;
    }

    .parents-table tbody tr:hover {
        background-color: #eef4fa;
        cursor: pointer;
    }

/* Dropdown filter styling */
.filter-dropdown {
    margin-bottom: 15px; /* Reduced space between dropdown and table */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

    .filter-dropdown label {
        font-weight: bold;
        margin-right: 8px;
        font-size: 16px;
    }

    .filter-dropdown select {
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        background-color: #fff;
        color: #333;
        transition: all 0.3s ease;
        min-width: 180px; /* Adjusted dropdown width for tighter fit */
    }

        .filter-dropdown select:hover {
            background-color: #f4f4f4;
            border-color: #0077cc;
        }

/* Button styles */
.save-button, .cancel-button, .update-button, .delete-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.save-button {
    background-color: #28a745;
    color: white;
}

    .save-button:hover {
        background-color: #218838;
    }

.cancel-button {
    background-color: #6c757d;
    color: white;
}

    .cancel-button:hover {
        background-color: #5a6268;
    }

.update-button {
    background-color: #007bff;
    color: white;
}

    .update-button:hover {
        background-color: #0056b3;
    }

.delete-button {
    background-color: #dc3545;
    color: white;
}

    .delete-button:hover {
        background-color: #c82333;
    }

/* Adjusting button placement for closer grouping */
.parents-table .button-group {
    display: flex;
    justify-content: flex-start;
    gap: 8px; /* Reduces space between buttons */
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
    .parents-table table, .parents-table th, .parents-table td {
        font-size: 14px;
        padding: 12px;
    }

    .filter-dropdown {
        flex-direction: column;
        align-items: flex-start;
    }
}
