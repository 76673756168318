.course-details-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

h3 {
    font-size: 20px;
    font-weight: bold;
    color: #555;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    color: #444;
    margin-bottom: 10px;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    font-size: 16px;
    padding: 8px 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    border-radius: 4px;
    color: #333;
}
