.parent-details-container {
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
}

.parent-info {
    margin-bottom: 30px;
    background-color: #f3f4f6;
    padding: 20px;
    border-radius: 8px;
}

h2 {
    font-size: 28px;
    font-weight: bold;
    color: #1f2937;
    margin-bottom: 20px;
}

h3 {
    font-size: 22px;
    color: #3b82f6;
    margin-bottom: 15px;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

li {
    margin-bottom: 15px;
    font-size: 18px;
    color: #374151;
    background-color: #e5e7eb;
    padding: 10px 15px;
    border-radius: 6px;
}

p {
    font-size: 16px;
    color: #6b7280;
    margin: 5px 0;
}

strong {
    font-weight: 600;
    color: #111827;
}

button {
    background-color: #3b82f6;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

    button:hover {
        background-color: #2563eb;
    }
