/* General page background */
body {
	background-color: #f0f2f5;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Common table and list container styles */
.common-container {
	margin: 20px auto;
	max-width: 1200px;
	background-color: white;
	padding: 20px;
	border-radius: 12px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

	.common-container h2 {
		color: #444;
		text-align: center;
		font-size: 28px;
		margin-bottom: 20px;
		font-weight: 600;
	}

/* Table styles */
.common-table {
	width: 100%;
	border-collapse: collapse;
	margin: 10px 0;
}

	.common-table th,
	.common-table td {
		padding: 14px 18px;
		text-align: left;
		font-size: 16px;
	}

	.common-table th {
		background-color: #0077cc;
		color: white;
		font-weight: bold;
		text-transform: uppercase;
	}

	.common-table td {
		border-bottom: 1px solid #ddd;
	}

/* Buttons */
button {
	padding: 8px 12px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 14px;
	transition: background-color 0.3s ease;
}

	button.update-course-btn {
		background-color: #007bff;
		color: white;
	}

	button.delete-course-btn {
		background-color: #dc3545;
		color: white;
	}

	button.add-course-btn {
		background-color: #28a745;
		color: white;
	}
